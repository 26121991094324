<template>
  <div class="wrapper-center-vertical login">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-center">
          <img src="../../assets/images/itfa-logo.png" alt="Itfa" class="logo-login img-fluid">
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
        </div>
        <b-form @submit.prevent="send" class="col-12 col-sm-10 col-md-6 text-center">
          <h5>{{$t('resetPassword')}}</h5>
          <hr>
          <div class="form-group text-left">
            <label for="email">{{$t('enterYourEmail')}}</label>
            <b-form-input v-model="email" v-validate="'required|email'" id="email" type="email" name="email" :state="validateState('email')" />
            <span class="error-inputs">{{ errors.first('password') }}</span>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-button class="mb-3" block variant="primary" type="submit">{{$t('submit')}}</b-button>
            </div>
            <div class="col-12 col-md-6">
              <b-button class="mb-3" block :to="{ name: 'Login', params: { lang: this.lang } }">{{$t('return')}}</b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      email: ''
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'isAuthenticated'
    ])
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'Dashboard', params: { lag: this.lang } })
    }
    document.title = `${this.$t('resetPassword')} - ITFA`
  },
  methods: {
    send () {
      this.$validator.validate().then(result => {
        if (result) {
          const path = `${this.lang}/reset-password/send-link`
          const payload = {
            email: this.email,
            lang: this.lang
          }
          this.$axios.post(path, payload).then((response) => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
            this.$router.push({ name: 'Login', params: { lang: this.lang } })
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
